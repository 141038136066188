import { Col, Row, message, Tabs, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Select } from "antd";
import { fetchAllSubcategory } from "redux/features/subcategory";
import { fetchAllWajib } from 'redux/features/wajib_pajak';
import { fetchAllTags } from "redux/features/tags";
import { getUserProfile } from "redux/features/auth";
import {
  addProject,
  fetchOneProject,
  updateProject,
} from "redux/features/projects";
import { MyEditor } from "../../../components/Editor";
import moment from "moment";

const selectStyle = {
  width: "100%",
  backgroundColor: "white",
};

const kabupatens = [
  { "label": "SUMATERA SELATAN", "value": "SUMATERA SELATAN" },
  { "label": "BANYUASIN", "value": "BANYUASIN" },
  { "label": "EMPAT LAWANG", "value": "EMPAT LAWANG" },
  { "label": "LAHAT", "value": "LAHAT" },
  { "label": "MUARA ENIM", "value": "MUARA ENIM" },
  { "label": "MUSI BANYUASIN", "value": "MUSI BANYUASIN" },
  { "label": "MUSI RAWAS", "value": "MUSI RAWAS" },
  { "label": "MUSI RAWAS UTARA", "value": "MUSI RAWAS UTARA" },
  { "label": "OGAN ILIR", "value": "OGAN ILIR" },
  { "label": "OGAN KOMERING ILIR", "value": "OGAN KOMERING ILIR" },
  { "label": "OGAN KOMERING ULU", "value": "OGAN KOMERING ULU" },
  { "label": "OGAN KOMERING ULU SELATAN", "value": "OGAN KOMERING ULU SELATAN" },
  { "label": "OGAN KOMERING ULU TIMUR", "value": "OGAN KOMERING ULU TIMUR" },
  { "label": "PENUKAL ABAB LEMATANG ILIR", "value": "PENUKAL ABAB LEMATANG ILIR" },
  { "label": "LUBUKLINGGAU", "value": "LUBUKLINGGAU" },
  { "label": "PAGAR ALAM", "value": "PAGAR ALAM" },
  { "label": "PALEMBANG", "value": "PALEMBANG" },
  { "label": "PRABUMULIH", "value": "PRABUMULIH" },
  { "label": "LAINNYA", "value": "LAINNYA" },
]

const opds = [
  { "label": "DINAS PENDIDIKAN", "value": "DINAS PENDIDIKAN" },
  { "label": "DINAS KESEHATAN", "value": "DINAS KESEHATAN" },
  { "label": "DINAS PEKERJAAN UMUM DAN PENATAAN RUANG", "value": "DINAS PEKERJAAN UMUM DAN PENATAAN RUANG" },
  { "label": "DINAS PERHUBUNGAN", "value": "DINAS PERHUBUNGAN" },
  { "label": "DINAS SOSIAL", "value": "DINAS SOSIAL" },
  { "label": "DINAS PERTANIAN", "value": "DINAS PERTANIAN" },
  { "label": "DINAS KETAHANAN PANGAN", "value": "DINAS KETAHANAN PANGAN" },
  { "label": "DINAS LINGKUNGAN HIDUP", "value": "DINAS LINGKUNGAN HIDUP" },
  { "label": "DINAS PARIWISATA", "value": "DINAS PARIWISATA" },
  { "label": "DINAS PERDAGANGAN", "value": "DINAS PERDAGANGAN" },
  { "label": "DINAS PERINDUSTRIAN", "value": "DINAS PERINDUSTRIAN" },
  { "label": "DINAS KOMUNIKASI DAN INFORMATIKA", "value": "DINAS KOMUNIKASI DAN INFORMATIKA" },
  { "label": "DINAS KEPENDUDUKAN DAN PENCATATAN SIPIL", "value": "DINAS KEPENDUDUKAN DAN PENCATATAN SIPIL" },
  { "label": "DINAS PEMUDA DAN OLAHRAGA", "value": "DINAS PEMUDA DAN OLAHRAGA" },
  { "label": "DINAS PERPUSTAKAAN DAN KEARSIPAN", "value": "DINAS PERPUSTAKAAN DAN KEARSIPAN" },
  { "label": "DINAS PEMBERDAYAAN PEREMPUAN DAN PERLINDUNGAN ANAK", "value": "DINAS PEMBERDAYAAN PEREMPUAN DAN PERLINDUNGAN ANAK" },
  { "label": "DINAS PEMBERDAYAAN MASYARAKAT DAN DESA", "value": "DINAS PEMBERDAYAAN MASYARAKAT DAN DESA" },
  { "label": "DINAS KOPERASI DAN UMKM", "value": "DINAS KOPERASI DAN UMKM" },
  { "label": "BADAN PERENCANAAN PEMBANGUNAN DAERAH", "value": "BADAN PERENCANAAN PEMBANGUNAN DAERAH" },
  { "label": "BADAN PENGELOLAAN KEUANGAN DAERAH", "value": "BADAN PENGELOLAAN KEUANGAN DAERAH" },
  { "label": "BADAN KEPEGAWAIAN DAERAH", "value": "BADAN KEPEGAWAIAN DAERAH" },
  { "label": "BADAN PENGAWASAN PEMBANGUNAN DAERAH", "value": "BADAN PENGAWASAN PEMBANGUNAN DAERAH" },
  { "label": "BADAN PENDAPATAN DAERAH", "value": "BADAN PENDAPATAN DAERAH" },
  { "label": "SEKRETARIAT DAERAH", "value": "SEKRETARIAT DAERAH" },
  { "label": "SEKRETARIAT DPRD", "value": "SEKRETARIAT DPRD" },
  { "label": "INSPEKTORAT DAERAH", "value": "INSPEKTORAT DAERAH" },
  { "label": "LAINNYA", "value": "LAINNYA" },
]

const sdgs = [
  { "label": "TANPA KEMISKINAN", "value": "TANPA KEMISKINAN" },
  { "label": "TANPA KELAPARAN", "value": "TANPA KELAPARAN" },
  { "label": "KEHIDUPAN SEHAT DAN SEJAHTERA", "value": "KEHIDUPAN SEHAT DAN SEJAHTERA" },
  { "label": "PENDIDIKAN BERKUALITAS", "value": "PENDIDIKAN BERKUALITAS" },
  { "label": "KESETARAAN GENDER", "value": "KESETARAAN GENDER" },
  { "label": "AIR BERSIH DAN SANITASI LAYAK", "value": "AIR BERSIH DAN SANITASI LAYAK" },
  { "label": "ENERGI BERSIH DAN TERJANGKAU", "value": "ENERGI BERSIH DAN TERJANGKAU" },
  { "label": "PEKERJAAN LAYAK DAN PERTUMBUHAN EKONOMI", "value": "PEKERJAAN LAYAK DAN PERTUMBUHAN EKONOMI" },
  { "label": "INDUSTRI, INOVASI, DAN INFRASTRUKTUR", "value": "INDUSTRI, INOVASI, DAN INFRASTRUKTUR" },
  { "label": "BERKURANGNYA KESENJANGAN", "value": "BERKURANGNYA KESENJANGAN" },
  { "label": "KOTA DAN PEMUKIMAN YANG BERKELANJUTAN", "value": "KOTA DAN PEMUKIMAN YANG BERKELANJUTAN" },
  { "label": "KONSUMSI DAN PRODUKSI YANG BERTANGGUNG JAWAB", "value": "KONSUMSI DAN PRODUKSI YANG BERTANGGUNG JAWAB" },
  { "label": "PENANGANAN PERUBAHAN IKLIM", "value": "PENANGANAN PERUBAHAN IKLIM" },
  { "label": "EKOSISTEM LAUTAN", "value": "EKOSISTEM LAUTAN" },
  { "label": "EKOSISTEM DARATAN", "value": "EKOSISTEM DARATAN" },
  { "label": "PERDAMAIAN, KEADILAN, DAN KELEMBAGAAN YANG TANGGUH", "value": "PERDAMAIAN, KEADILAN, DAN KELEMBAGAAN YANG TANGGUH" },
  { "label": "KEMITRAAN UNTUK MENCAPAI TUJUAN", "value": "KEMITRAAN UNTUK MENCAPAI TUJUAN" },
  { "label": "LAINNYA", "value": "LAINNYA" },
]

const categories = [
  { "label": "PENDIDIKAN", "value": "PENDIDIKAN" },
  { "label": "KESEHATAN", "value": "KESEHATAN" },
  { "label": "KETAHANAN PANGAN", "value": "KETAHANAN PANGAN" },
  { "label": "FASILITAS UMUM", "value": "FASILITAS UMUM" },
  { "label": "LINGKUNGAN", "value": "LINGKUNGAN" },
  { "label": "PEMBERDAYAAN EKONOMI", "value": "PEMBERDAYAAN EKONOMI" },
  { "label": "INFRASTRUKTUR", "value": "INFRASTRUKTUR" },
  { "label": "SOSIAL DAN BUDAYA", "value": "SOSIAL DAN BUDAYA" },
  { "label": "PEMBERDAYAAN MASYARAKAT", "value": "PEMBERDAYAAN MASYARAKAT" },
  { "label": "KESELAMATAN DAN KESEJAHTERAAN", "value": "KESELAMATAN DAN KESEJAHTERAAN" },
  { "label": "KEMISKINAN", "value": "KEMISKINAN" },
  { "label": "BANTUAN UMKM", "value": "BANTUAN UMKM" },
  { "label": "LAINNYA", "value": "LAINNYA" },
]

const rules = [
  {
    required: true,
    message: "WAJIB DIISI!",
  },
];

export const DETAIL_PROJECT = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();
  const [idBody, setIdBody] = useState("");
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState("");
  const [enBody, setEnBody] = useState("");
  const [subcategories, setSubcategories] = useState();
  const [tags, setTags] = useState();
  const [role, setRole] = useState('');

  const [kabupaten, setKabupaten] = useState("");
  const [opd, setOpd] = useState("");
  const [kategori, setKategori] = useState("");
  const [sdg, setSdg] = useState("");

  const onFinish = (values) => {
    if (location?.state?.id) {
      updateData({
        ...values,
        id: location.state.id,
      });
    } else {
      createData(values);
    }
  };

  const getProfile = async () => {
    try {
      const response = await dispatch(getUserProfile()).unwrap();
      setRole(response.data.user.role_id)
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const getAllCompanies = async () => {
    try {
      const { data } = await dispatch(fetchAllWajib({ limit: 1000 })).unwrap();
    console.log(data)
      const list_pt = data.data.map(item => ({
        value: item.perusahaan,
        label: item.perusahaan
      }));
      
      setCompanies(list_pt);
    } catch (err) {
      console.log(err)
    }
  }

  const createData = async (values) => {
    try {
      await dispatch(addProject({
        ...values,
        kabupaten,
        sdg,
        kategori,
        opd,
        endDate: values.deadline,
        sgd: values.sdg,
        pic_id: 1,
        status: "OPEN",
        cost: parseInt(values.cost),
        actual: parseInt(values.actual)
      })).unwrap();
      history.push("/app/projects");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const getTags = async () => {
    try {
      const response = await dispatch(fetchAllTags()).unwrap();
      setTags(
        response.data.map((tag) => {
          return {
            value: tag.id,
            label: tag.name,
          };
        })
      );
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const updateData = async (values) => {
    console.log(values)
    try {
      await dispatch(updateProject({
        ...values,
        pic_id: 1,
        sgd: values.sdg,
        endDate: "2024-08-17",
        cost: parseInt(values.cost),
        actual: parseInt(values.actual)
      })).unwrap();
      history.push("/app/projects");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneProject(id)).unwrap();
      setKabupaten(data.data.kabupaten)
      setKategori(data.data.kategori)
      setSdg(data.data.sdg)
      setOpd(data.data.opd)
      form.setFieldsValue({
        ...data.data,
        startDate: moment(data.data.startDate),
        // endDate:moment(data.data.endDate),
        deadline: moment(data.data.deadline),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getSubcategories = async () => {
    try {
      const response = await dispatch(fetchAllSubcategory()).unwrap();
      setSubcategories(
        response.data.map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        })
      );
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      getDataById(location?.state?.id);

    }
    getSubcategories();
    getTags();
    getProfile()
    getAllCompanies()
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Tambahkan Proyek Baru</h2>
          <p>Tolong isi proyek baru di form ini sesuai dengan data</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Nama Proyek"
                name="name"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Penyedia Dana"
                name="perusahaan"
              >
                <Select options={companies} />
              </Form.Item>

              <Form.Item
                label="Koordinat (Link Google Map)"
                name="koordinat"
              >
                <Input defaultValue={'-'} />
              </Form.Item>

              <Form.Item
                label="Latitude"
                name="latitude"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Longitude"
                name="longitude"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Alamat Lengkap"
                name="lokasi"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Kelurahan (Contoh: SILABERANTI)"
                name="kelurahan"
              >
                <Input defaultValue={"LAINNYA"} />
              </Form.Item>

              <Form.Item
                label="Kecamatan (Contoh: SEBERANG ULU I)"
                name="kecamatan"
              >
                <Input defaultValue={"LAINNYA"} />
              </Form.Item>

              <Form.Item
                label="Kabupaten"
                name="kabupaten"
              >
                <Select defaultValue={kabupaten} options={kabupatens} />
              </Form.Item>

              <Form.Item
                label="OPD"
                name="opd"
              >
                <Select defaultValue={opd} options={opds} />
              </Form.Item>

              <Form.Item
                label="Kategori"
                name="kategori"
              >
                <Select defaultValue={kategori} options={categories} />
              </Form.Item>

              <Form.Item
                label="SDG"
                name="sdg"
              >
                <Select defaultValue={sdg} options={sdgs} />
              </Form.Item>

              <Form.Item
                label="Pelaksana"
                name="pelaksana"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Tanggal Mulai"
                name="startDate"
              >
                <DatePicker format={'YYYY-MM-DD'} />
              </Form.Item>

              <Form.Item
                label="Tanggal Selesai"
                name="deadline"

              >
                <DatePicker format={'YYYY-MM-DD'} />
              </Form.Item>

              <Form.Item
                label="Nilai Proyek"
                name="cost"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Sertifikat"
                name="certificate"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Aktual Proyek"
                name="actual"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Subcategory"
                name="vendor_id"
              >
                <Select
                  defaultValue="Not Picked"
                  style={selectStyle}
                  options={subcategories}
                />
              </Form.Item>

              <Form.Item
                label="Status"
                name="status"
                rules={rules}
              >
                <Select
                  defaultValue="Not Picked"
                  style={selectStyle}
                  options={[{
                    label: "OPEN",
                    value: "OPEN"
                  }, {
                    label: "CLOSED",
                    value: "CLOSED"
                  }]}
                />
              </Form.Item>

              <Form.Item
                label="File URL"
                name="file"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="No Phone"
                name="no_phone"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description (Max 255 Characters)"
                name="description"
              >
                <Input />
              </Form.Item>

              <Tabs>
                <Tabs.TabPane tab="Indonesian" label="item-1">
                  <p>Writes Content in Indonesian</p>
                  <Form.Item>
                    <MyEditor
                      data={idBody}
                      setState={setIdBody}
                      defaultValue="Indonesian Content"
                    />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane tab="English" label="item-2">
                  <p>Writes Content in English</p>
                  <Form.Item>
                    <MyEditor
                      data={enBody}
                      setState={setEnBody}
                      defaultValue="English Content"
                    />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>

              {
                role === 1 && (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                )
              }
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DETAIL_PROJECT;
