import { strings } from 'res';
import { DashboardIcon, OrderIcon, ReportIcon } from "../assets/svg/icon";
import { UserOutlined, SettingOutlined, BranchesOutlined, AimOutlined, FileOutlined} from '@ant-design/icons';

const all_access = [1]
const reguler_access = [1,2]

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      breadcrumb: false,
      access:reguler_access,
      submenu: []
    },
    {
      key: "Proyek Saya",
      path: strings.navigation.path.my_projects,
      title: "Proyek Saya",
      icon: FileOutlined,
      breadcrumb: false,
      access:reguler_access,
      submenu: []
    },
    {
      key: "Proyek CSR",
      path: strings.navigation.path.projects,
      title: "Proyek CSR",
      icon: AimOutlined,
      breadcrumb: false,
      access:reguler_access,
      submenu: []
    },
    {
      key: "BAST",
      path: strings.navigation.path.contents,
      title: "BAST",
      icon: ReportIcon,
      breadcrumb: false,
      access:all_access,
      submenu: []
    },
    {
      key: "Wajib CSR",
      path: strings.navigation.path.wajib_pajak,
      title: "Wajib CSR",
      icon: OrderIcon,
      breadcrumb: false,
      access:all_access,
      submenu: []
    },
    // {
    //   key: "Daftar Vendor",
    //   path: strings.navigation.path.vendor,
    //   title: "Daftar Vendor",
    //   icon: BranchesOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: "Tags",
    //   path: strings.navigation.path.tags,
    //   title: "Tags",
    //   icon: TagOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: "Pengguna",
      path: strings.navigation.path.users,
      title: "Pengguna",
      icon: UserOutlined,
      access:all_access,
      breadcrumb: false,
      submenu: []
    },
  ]
}]

const ControlTree = [{
  key: "Pengaturan",
  path: strings.navigation.path.settings,
  title: "Pengaturan",
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: SettingOutlined,
      breadcrumb: false,
      access:reguler_access,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;