import { Col, Row, Select, message } from 'antd';
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addUser, fetchOneUser, updateUser } from 'redux/features/user';
import { fetchAllWajib } from 'redux/features/wajib_pajak';
import Password from 'antd/lib/input/Password';


const perusahaan = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'user',
    label: 'User',
  }
]

export const USER = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation()

  const [companies, setCompanies] = useState([])
  const [role, setRole] = useState("user")

  const handleChange = (value) => {
    setRole(value)
  };

  const onFinish = (values) => {
    if (location?.state?.id) {
      updateData(values)
    } else {
      createData(values)
    }
  }

  const createData = async (values) => {
    try {
      await dispatch(addUser(values)).unwrap()
      history.push("/app/users")
    } catch (error) {
      message.error(error?.message || 'Failed to register')
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updateUser({ ...values, id: location?.state?.id })).unwrap()
      history.push("/app/users")
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneUser(id)).unwrap()
      form.setFieldsValue({
        ...data.data
      });
    } catch (err) {
      console.log(err)
    }
  }

  const getAllCompanies = async () => {
    try {
      const { data } = await dispatch(fetchAllWajib({ limit: 1000 })).unwrap();
    console.log(data)
      const list_pt = data.data.map(item => ({
        value: item.perusahaan,
        label: item.perusahaan
      }));
      
      setCompanies(list_pt);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllCompanies()
    if (location?.state?.id) {
      getDataById(location?.state?.id)
    }
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Add/Update User</h2>
          <p>Please fill your data</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Perusahaan"
                name="perusahaan"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company!',
                  },
                ]}
              >
                <Select options={companies} />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input password!',
                  },
                ]}
              >
                <Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default USER