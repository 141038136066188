import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import StatisticWidget from "components/shared-components/StatisticWidget";
import { useDispatch } from "react-redux";
import { fetchAllCategory } from "redux/features/category";
import { fetchAllContent } from "redux/features/content";
import { fetchAllSubcategory } from "redux/features/subcategory";
import { fetchProjectSummary } from "redux/features/projects";
import { fetchWajibSummary } from "redux/features/wajib_pajak";
import { PROJECTS } from "../projects";

let IDRFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'IDR',
});

export const DefaultDashboard = () => {
  const dispatch = useDispatch();
  const [target, setTarget] = useState(0);
  const [actual, setActual] = useState(0);
  const [persentase, setPersentase] = useState(0);

  const getAllDashboardData = async () => {
    try {
      const target = await dispatch(fetchWajibSummary()).unwrap();
      setTarget(target.data._sum.target);
      const aktual = await dispatch(fetchProjectSummary()).unwrap();
      setActual(aktual.data._sum.cost);
      setPersentase((parseFloat(target.data._sum.cost)/parseFloat(target.data._sum.target)))
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  useEffect(() => {
    getAllDashboardData()
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>
            Jika menemukan bug ataupun error pada aplikasi ini bisa langsung hubungi kami di 085899731884
          </p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Proyek CSR`}
                value={3}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Target CSR 2024`}
                value={IDRFormat.format(target)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`CSR Tersalurkan 2024`}
                value={IDRFormat.format(actual)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center"}}
                title={`Progress di 2024`}
                value={<div style={{color: (actual/target*100).toFixed(2) < 50 ? 'red' : 'green' }}>{`${(actual/target*100).toFixed(2)}%`}</div>}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} key={2}>
              <PROJECTS></PROJECTS>
            </Col>
          </Row>
        </Col>
      </Row>

    </>
  );
};

export default withRouter(DefaultDashboard);
